.navigation {
  position: absolute;
  width: 100%;
  color: $color-gray-white;
  pointer-events: none;
  backdrop-filter: blur(10px);
  z-index: z('navigation');
  mix-blend-mode: difference;

  @include media('<tablet') {
    background: $color-gray-black;
    backdrop-filter: none;
    mix-blend-mode: normal;
  }
}
.navigation__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 3rem 8rem;

  @include media('<tablet') {
    padding: 5rem;
  }
}
.navigation__logo {
  font-size: 2.5rem;
  pointer-events: auto;
  text-transform: uppercase;
}
.navigation__list {
  font-size: 2.5rem;
  pointer-events: auto;
  text-transform: uppercase;
}
