.about__header{
  margin: 0 8rem;
  position: relative;

  @include media('<tablet'){
    margin: 0;
  }
}

.about__header__wrapper{
  display: flex;
  padding-top: 16rem;
  justify-content: space-between;

  @include media('<tablet'){
    flex-direction: column;
    padding-top: 8rem;
  }
}

.about__header__bio{
  padding-top: 5rem;

  @include media('<tablet'){
    margin: 0 5rem;
  }

  .about__header__hi{
    font-family: $font-italiana;
    font-size: 22.5rem;
    text-transform: uppercase;

    @include media('<tablet'){
      font-size: 18rem;
    }
  }

  .about__header__title{
    font-size: 3rem;
    letter-spacing: .5rem;
    line-height: 1.6;
    margin-left: 23rem;
    margin-top: 2rem;
    text-transform: uppercase;
    width: 62.7rem;

    @include media('<tablet'){
      margin-left: 0;
      margin-top: 4rem;
      width: 100%;
    }
  }

  .about__header__description{
    color: $color-gray-medium;
    font-size: 2.5rem;
    line-height: 1.8;
    letter-spacing: .3rem;
    margin-top: 9rem;
    width: 62.7rem;

    @include media('<tablet'){
      margin: 6rem 0 10rem;
      width: 100%;
    }
  }
}

.about__header__portrait{
  margin-right: 8rem;

  @include media('<tablet'){
    margin-right: 0;
  }

  .about__header__media{
    height: 109.7rem;
    width: 77rem;
    position: relative;

    @include media('<tablet'){
      position: static;
      width: 100%;
      height: auto;
    }

    .about__header__image{
      @extend %cover;

      object-fit: cover;

      @include media('<tablet'){
        position: static;
      }
    }
  }
}