.preloader {
  @extend %center;
  @extend %cover;

  background: $color-gray-white;
  z-index: z('preloader');
}

.preloader__wrapper {
  align-items: center;
  color: $color-gray-black;
  display: flex;
  flex-direction: column;
  font-family: $font-italiana;
  text-transform: uppercase;

  .preloader__count {
    font-size: 25rem;
  }

  .preloader__line {
    height: 1px;
    width: 80%;
    transform: scaleX(0%);
    background-color: $color-gray-black;
    margin: 1rem 0 4rem;
  }

  .preloader__info {
    font-size: 5rem;
  }
}
