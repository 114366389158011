*,
*::after,
*::before {
  box-sizing: border-box;
}

*::selection {
  background-color: $color-gray-medium;
  color: $color-gray-black;
  mix-blend-mode: difference;
}

html {
  @extend %cover;

  font-size: calc(100vw / 1920 * 10);
  line-height: 1;
  overflow: hidden;
  position: fixed;

  @include media('<tablet') {
    font-size: calc(100vw / 750 * 10);
  }
}

body {
  @extend %cover;

  font-family: $font-ubuntu;
  font-weight: 200;
  position: fixed;
  background: $color-gray-black;
}

.word__link {
  color: inherit;
  outline: none;
  text-decoration: none;
  position: relative;
}

.word__link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: $color-gray-white;
  mix-blend-mode: difference;
  bottom: -1rem;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.word__link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

.webgl {
  @extend %cover;

  pointer-events: none;
  z-index: z('canvas');
}

.content {
  @extend %cover;

  transform: translateY(30%);
  opacity: 0;
  z-index: z('content');
}

[data-webgl='image'] {
  visibility: hidden;
}

.title,
.title__inner,
.paragraph,
.paragraph__inner {
  opacity: 0;

  span {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;

    span {
      opacity: 0;
      transform: translateY(100%);
    }
  }
}

.opacity__inner {
  opacity: 0;
}

.preText {
  span {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
  }
}
