.section__description {
  height: 104rem;
  width: 100%;
  position: relative;

  @include media('<tablet') {
    height: auto;
    padding: 10rem 0;
  }
}

.section__description__wrapper {
  align-items: center;
  height: 100%;
  display: flex;

  @include media('<tablet') {
    align-items: flex-start;
    flex-direction: column;
  }

  .section__description__principal {
    margin-left: 8rem;
    width: 83rem;

    @include media('<tablet') {
      padding: 0 5rem;
      margin-left: 0;
      width: 100%;
    }

    .section__principal {
      font-size: 3.5rem;
      letter-spacing: 0.5rem;
      line-height: 1.2;
      margin: 5rem 0;
      text-transform: uppercase;

      @include media('<tablet') {
        margin: 0 0 5rem;
      }
    }
  }

  .section__description__star {
    position: absolute;
    right: -40rem;

    @include media('<tablet') {
      right: -15rem;
      bottom: 3rem;
    }

    .section__description__star__media {
      height: 80rem;
      width: 80rem;
      position: relative;

      @include media('<tablet') {
        height: 30rem;
        width: 30rem;
      }

      .section__description__star__image {
        @extend %cover;

        object-fit: cover;
      }
    }
  }

  .section__description__highlights {
    bottom: 15rem;
    position: absolute;
    right: 8rem;

    @include media('<tablet') {
      margin: 10rem 5rem 0 5rem;
      position: static;
    }

    .section__highlight {
      font-size: 2.5rem;
      letter-spacing: 0.5rem;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }
}
