.footer__about__contact__networks {
  display: flex;
  font-size: 2.5rem;
  justify-content: space-between;
  text-transform: uppercase;
  color: $color-blue;

  @include media('<tablet') {
    padding-bottom: 5rem;
  }

  .footer__contact__items {
    display: flex;

    .footer__network {
      margin-right: 3rem;
    }
  }
}
