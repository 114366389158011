@font-face {
    font-family: $font-italiana;
    src: url('../fonts/Italiana-Regular.woff2') format('woff2'),
        url('../fonts/Italiana-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-ubuntu;
    src: url('../fonts/Ubuntu-Regular.woff2') format('woff2'),
        url('../fonts/Ubuntu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-ubuntu;
    src: url('../fonts/Ubuntu-Light.woff2') format('woff2'),
        url('../fonts/Ubuntu-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-ubuntu;
    src: url('../fonts/Ubuntu-Bold.woff2') format('woff2'),
        url('../fonts/Ubuntu-Bold.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}