.section__moreinfo{
  position: relative;
  padding: 20rem 8rem 10rem;

  @include media('<tablet'){
    padding: 10rem 5rem;
  }
}

.section__moreinfo__inspiration{
  display: flex;
  flex-direction: column;
  text-transform: uppercase;

  .section__moreinfo__phrase{
    font-family: $font-italiana;
    font-size: 10rem;
    text-align: center;
  }

  .section__moreinfo__author{
    align-items: center;
    display: flex;
    margin-right: 20rem;
    margin-top: 10rem;
    align-self: flex-end;

    @include media('<tablet'){
      margin-right: 0;
      margin-top: 5rem;
      align-self: center;
    }

    .author__line{
      background: $color-gray-white;
      height: 1px;
      margin-right: 4rem;
      width: 15rem;
    }
    .author__name{
      font-size: 4rem;
      letter-spacing: .5rem;
    }
  }
}

.section__moreinfo__description{
  margin-top: 5rem;

  .section__moreinfo__text{
    color: $color-gray-medium;
    font-size: 2.5rem;
    line-height: 1.8;
    letter-spacing: .3rem;
    margin-left: 10rem;
    width: 62.7rem;

    @include media('<tablet'){
      width: 100%;
      margin-left: 0;
      margin-top: 10rem;
    }
  }
}