$color-gray-black: #1f1e1d;
$color-gray-black-black: #1b1611;
$color-gray-white: #e4e4e4;
$color-gray-medium: #a098ab;
$color-blue: #44bbff;

$font-italiana: 'Italiana';
$font-ubuntu: 'Ubuntu';

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$z-indexes: (
  'loadpage',
  'preloader',
  'adcookies',
  'navigation',
  'lines',
  'content',
  'canvas'
);
