.loadpage {
  @extend %center;
  @extend %cover;

  background: $color-gray-white;
  transform: translateY(100%);
  z-index: z('loadpage');
}

.loadpage__text {
  color: $color-gray-black;
  font-family: $font-italiana;
  font-size: 9rem;
  text-align: center;
  width: 90rem;

  @include media('<tablet') {
    width: 60rem;
  }
}
